import { graphql, useStaticQuery } from 'gatsby';
import React, { Fragment } from 'react';
import SEO from '../components/SEO';
import PortfolioFilter from '../components/PortfolioFilter';
import Footer from '../sections/Footer';
import queryString from 'query-string';

const portfolioQuery = graphql`
  {
    page: datoCmsPortfolioPage {
      title
      bannerImage {
        url
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function Portfolio({ location }) {
  const { type } = queryString.parse(location.search);
  const data = useStaticQuery(portfolioQuery);
  const { title, bannerImage, seoMetaTags } = data.page;

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <PortfolioFilter
        banner={bannerImage?.url}
        title={title}
        defaultType={type}
      />
      <Footer />
    </Fragment>
  );
}
