import { graphql, navigate, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import FlipMove from 'react-flip-move';
import styled from 'styled-components';

const PortfolioItem = styled.div`
  position: relative;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scale(1.04);
    .overlay {
      background: transparent;
      color: transparent;
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 15px;
    text-align: center;
    transition: all ease-in-out 0.3s;
    color: white;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
`;

const portfolioQuery = graphql`
  {
    projects: allDatoCmsPortfolioPost(sort: { fields: position }) {
      nodes {
        id
        description
        title
        slug
        category {
          slug
        }
        imageGallery {
          url
        }
      }
    }

    categories: allDatoCmsPortfolioCategory(sort: { fields: position }) {
      nodes {
        id
        categorySlug: slug
        categoryName
      }
    }
  }
`;

export default function PortfolioFilter({ title, banner, defaultType = null }) {
  const [selectedCategory, setSelectedCategory] = useState(defaultType);

  const data = useStaticQuery(portfolioQuery);
  const { nodes: categories } = data.categories;
  const { nodes: projects } = data.projects;

  console.log(projects);

  const btnClassCommon =
    'hover:bg-orange-theme hover:border-transparent py-2 px-4 border border-gray-400 m-1';

  const btnClassSelected =
    btnClassCommon + ' ' + 'bg-orange-theme border-transparent';

  const selectCategory = e => setSelectedCategory(e.target.name);
  const selectAll = () => setSelectedCategory(null);

  const firstThree = categories.slice(0, 3);
  const theRest = categories.slice(3, categories.length);

  return (
    <div className="py-12 bg-gray-200">
      <img
        src={banner}
        alt="banner"
        className="w-full"
        style={{
          maxHeight: '60vh',
        }}
      />
      {/* <div
        className="mt-8 flex justify-center items-center bg-contain bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${banner})`, height: '50vh' }}
      ></div> */}
      <div className="container md:px-20 py-8">
        <h1 className="text-5xl font-black tracking-wider text-black text-center mb-6">
          {title}
        </h1>
        <div className="grid grid-cols-2 md:grid-cols-4 items-stretch">
          <button
            className={selectedCategory ? btnClassCommon : btnClassSelected}
            onClick={selectAll}
          >
            All
          </button>
          {firstThree.map(({ id, categorySlug, categoryName }) => (
            <button
              key={'btn' + id}
              className={
                selectedCategory == categorySlug
                  ? btnClassSelected
                  : btnClassCommon
              }
              name={categorySlug}
              onClick={selectCategory}
            >
              {categoryName}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-2 md:grid-cols-5 items-stretch">
          {theRest.map(({ id, categorySlug, categoryName }) => (
            <button
              key={'btn' + id}
              className={
                selectedCategory == categorySlug
                  ? btnClassSelected
                  : btnClassCommon
              }
              name={categorySlug}
              onClick={selectCategory}
            >
              {categoryName}
            </button>
          ))}
        </div>

        <FlipMove className="flex flex-wrap mt-8">
          {projects
            .filter(portfolio =>
              selectedCategory
                ? portfolio?.category?.slug == selectedCategory
                : true
            )
            .map(portfolio => (
              <button
                onClick={() => {
                  navigate('/portfolio/' + portfolio.slug);
                }}
                key={portfolio.id}
                className="w-full sm:w-1/3 md:w-1/2 lg:w-1/3  p-2"
              >
                <PortfolioItem>
                  <div className="overlay bg-opacity-75">
                    <h3 className="text-xl">{portfolio.title}</h3>
                    <p className="text-sm">{portfolio.description}</p>
                  </div>
                  <img src={portfolio.imageGallery[0].url} alt="gallery" />
                </PortfolioItem>
              </button>
            ))}
        </FlipMove>
        <div style={{ minHeight: '400px' }}></div>
      </div>
    </div>
  );
}
